import {parse} from 'url';
const axios = require('axios');
//import rp from 'request-promise';

//const RE_VIMEO = /^(?:\/video|\/channels\/[\w-]+|\/groups\/[\w-]+\/videos)?\/(\d+)$/;
const RE_YOUTUBE = /^(?:\/embed)?\/([\w-]{10,12})$/;

async function getThumb(url) {
    try {
        if (!url) {
            return null;
        }
    
        const urlobj = parse(url, true);
        
        //youtube
        if (['www.youtube.com', 'youtube.com', 'youtu.be'].indexOf(urlobj.host) !== -1) {
            let video_id = null;
            if ('v' in urlobj.query) {
                if (urlobj.query.v && urlobj.query.v.match(/^[\w-]{10,12}$/)) {
                    video_id = urlobj.query.v;
                }
            } else {
                const match = RE_YOUTUBE.exec(urlobj.pathname);
                if (match) {
                    video_id = match[1];
                }
            }
    
            if (video_id) {
                return `http://img.youtube.com/vi/${video_id}/hqdefault.jpg`;
            }
        }
    
        //vimeo
        if (['www.vimeo.com', 'vimeo.com', 'player.vimeo.com'].indexOf(urlobj.host) !== -1) {
    
            let res = await axios.get('https://vimeo.com/api/oembed.json?url=' + url);
            if (res && res.data) {
                let thumbnailUrl = res.data.thumbnail_url;
                if (thumbnailUrl) {
                    // Améliorer la résolution du thumbnail, ex. on recoit une résolution de 295x166
                    let index = thumbnailUrl.lastIndexOf('_');
                    if (index > 0) {
                        let resolutionString = thumbnailUrl.substr(index + 1, thumbnailUrl.length - index);
                        if (resolutionString.indexOf('x') > 0) {
                            thumbnailUrl = thumbnailUrl.substr(0, index) + "_640x480";
                        }
                    }
                }
                return thumbnailUrl;
            }
    
            // const match = RE_VIMEO.exec(urlobj.pathname);
            // if (match) {
            //     const video_id = match[1];
            //     return rp({
            //         uri: `https://vimeo.com/api/v2/video/${video_id}.json`,
            //         json: true
            //     })
            //     .then(data => {
            //         if (data) {
            //             return data[0].thumbnail_large;
            //         }
            //     }); 
            // }
        }
        return null;
    }
    catch (err) {
        console.log('err')
        return null;
    }
}

export {
    getThumb
}